




import { Prop, Component, Vue } from 'vue-property-decorator';
import { SurveyProperty, SurveySite } from '@/api';

import {
  select as d3Select,
  geoPath as d3GeoPath,
  geoMercator as d3GeoMercator,
} from 'd3';

import { rewind } from '@turf/turf';
import { getPropertyCollection } from '@/util';

@Component({ components: {} })
export default class PropertyOutline extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ default: null }) readonly site: SurveySite | null;

  mounted() {
    const w = 300;
    const h = 300;
    const padding = 32;

    const propertyFeatures = rewind(getPropertyCollection(this.property), {
      reverse: true,
    });
    const propertyOutline = rewind(this.property.geometry, { reverse: true });
    const points: { id: string; size: number; coords: [number, number] }[] = [];
    this.property.surveySites.forEach(site => {
      points.push({
        id: site.id as string,
        size: 10,
        coords: site.location.coordinates as [number, number],
      });
      points.push({
        id: site.id as string,
        size: 6,
        coords: site.location.coordinates as [number, number],
      });
    });

    const projection = d3GeoMercator().fitExtent(
      [
        [padding, padding],
        [w - padding, h - padding],
      ],
      propertyFeatures as never,
    );
    const geopath = d3GeoPath().projection(projection);
    const svg = d3Select(this.$el)
      .append('svg')
      .attr('viewBox', `0 0 ${w} ${h}`);

    svg
      .append('path')
      .attr('class', 'outline')
      .datum(propertyOutline)
      .attr('d', geopath);

    svg
      .selectAll('circle')
      .data(points)
      .enter()
      .append('circle')
      .attr('cx', d => (projection(d.coords) as [number, number])[0])
      .attr('cy', d => (projection(d.coords) as [number, number])[1])
      .attr('r', d => `${d.size}px`)
      .attr('class', 'point')
      .classed('point--active', d => !!this.site && d.id === this.site.id)
      .classed('point--outer', d => d.size === 10);
  }
}
