





















































import { Prop, Component, Vue } from 'vue-property-decorator';
import { FaunaSurvey, FaunaSurveyStatus, SurveyProperty } from '@/api';

import snackModule from '@/store/Snack';

import GetStartedSite from '@/components/common/GetStartedSite.vue';
import Stepper from '@/components/common/Stepper.vue';
import StepperItem from '@/components/common/StepperItem.vue';

import SurveyStep1 from '@/components/survey/SurveyStep1.vue';
import SurveyStep2 from '@/components/survey/SurveyStep2.vue';
import SurveyStep3 from '@/components/survey/SurveyStep3.vue';
import { NavigationGuardNext, Route } from 'vue-router';

import confirmDialog from '@/confirm-dialog';

@Component({
  components: {
    GetStartedSite,
    Stepper,
    StepperItem,
    SurveyStep1,
    SurveyStep2,
    SurveyStep3,
  },
})
export default class SurveyStepper extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  survey: FaunaSurvey | null = null;

  notFound = false;

  get surveyId() {
    return this.$route.params.surveyId;
  }

  get createMode() {
    return this.$route.name === 'property-surveys-create';
  }

  /*  Getter & setter */
  get step() {
    return parseInt((this.$route.query.step as string) || '1', 10);
  }

  set step(s: number) {
    this.$router
      .replace({
        query: { ...this.$route.query, step: s.toString() },
      })
      .catch(() => {
        // alll g
      });
  }

  canAccess(step: number) {
    switch (step) {
      case 1:
        return this.survey && this.survey.status === FaunaSurveyStatus.draft;
      case 2:
        return (
          this.survey &&
          this.survey.status === FaunaSurveyStatus.draft &&
          this.survey.id
        );
      case 3:
        return (
          this.survey &&
          this.survey.faunaMediaCount > 0 &&
          this.survey.snsStatusPredicted === this.survey.faunaMediaCount
        );

      default:
        return false;
    }
  }

  prevStep() {
    if (this.step > 0) {
      this.step -= 1;
    }
  }

  nextStep() {
    if (this.createMode && this.survey && this.survey.id) {
      this.$router.replace({
        name: 'property-surveys-edit',
        params: { surveyId: this.survey.id as string },
        query: { step: '2' },
      });
    } else if (this.step < 3) {
      this.step += 1;
    }
  }

  async navGuard(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      from.query.step === '1' &&
      this.$refs.stepComponent &&
      (this.$refs.stepComponent as SurveyStep1).unsavedChanges
    ) {
      const confirm = await confirmDialog({
        title: 'Unsaved changes will be lost',
        description:
          'You have unsaved changes. Are you sure you would like to continue?',
        buttons: [
          {
            key: 'confirm',
            title: 'Discard Changes',
            color: 'grey',
            text: true,
          },
          {
            key: 'cancel',
            title: 'Continue Editing',
            color: 'primary',
            outlined: false,
          },
        ],
      });
      if (confirm !== 'confirm') {
        return false;
      }
    }
    if (from.query.step === '2') {
      // TODO: fix this
    }
    next();
    return true;
  }

  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    return this.navGuard(to, from, next);
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    return this.navGuard(to, from, next);
  }

  async getUpdatedSurvey() {
    if (!this.surveyId) {
      throw new Error('this shouldnt happen');
    }

    try {
      const survey = (await FaunaSurvey.find(this.surveyId)).data;
      if (survey.status !== FaunaSurveyStatus.draft && this.step !== 3) {
        this.step = 3;
      } else if (this.step === 1) {
        this.step = 2;
      }
      this.survey = survey;
    } catch (e) {
      snackModule.setError({
        text: 'Error retrieving survey',
        errors: (e as ErrorResponse).response.errors,
      });
      this.notFound = true;
    }
  }

  mounted() {
    if (!this.surveyId) {
      this.survey = new FaunaSurvey({ status: FaunaSurveyStatus.draft });
      this.step = 1;
      return;
    }
    this.getUpdatedSurvey();
  }
}
