import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          lighten2: '#E8F9F6',
          lighten1: '#8AD6CA',
          base: '#4B9C8F',
          darken1: '#224F43',
          darken2: '#11291F',
        },
        secondary: '#F3F2ED', // cream
        tertiary: {
          lighten1: '#fafafa',
          base: '#f6f6f6', // grey
          darken1: '#eeeeee', // grey
          darken2: '#dddddd', // grey
        },
        resolved: {
          lighten2: '#E7F0FF',
          lighten1: '#91A8D0',
          base: '#5770A1',
          darken1: '#314572',
          darken2: '#172444',
        },
        conflict: {
          lighten2: '#FFF6E5',
          lighten1: '#E9C380',
          base: '#CF993C',
          darken1: '#AC700F',
          darken2: '#4A2D00',
        },
      },
    },
  },
});
