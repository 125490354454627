var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"server-items-length":_vm.total,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"items":_vm.faunaMedia,"show-select":true,"single-select":false,"disable-sort":true,"hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.filename",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"d-flex align-center gap-4 py-2"},[_c('div',[_vm._v(_vm._s(value))])])]}},{key:"item.snsStatus",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"d-flex gap-2"},[_c('div',{class:("sns-status--" + value)},[_vm._v(_vm._s(item.helpfulSnsStatus))]),(item.snsStatus === 'pending')?_c('v-tooltip',{attrs:{"color":"grey darken-4","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"conflict"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.helpfulSnsStatus))])]):_vm._e()],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"d-flex justify-end py-4 gap-2"},[_c('v-btn',{attrs:{"color":"grey","outlined":"","depressed":""},on:{"click":_vm.selectAll}},[_vm._v(" Select All ")]),(_vm.selected.length)?_c('v-btn',{attrs:{"color":"grey","outlined":"","depressed":""},on:{"click":_vm.deselectAll}},[_vm._v(" Deselect All ")]):_vm._e(),(_vm.selected.length)?_c('v-btn',{attrs:{"color":"red","outlined":"","depressed":""},on:{"click":_vm.deleteSelected}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete Selected ")],1):_vm._e(),_c('v-spacer'),_c('compact-pagination',{attrs:{"total":_vm.total,"itemsPerPage":_vm.itemsPerPage},on:{"refresh":_vm.update},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"persistent":"","min-width":"380","max-width":"480"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{staticClass:"bbox-dialog form-vertical-gap"},[_c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v("Deleting items...")]),_c('p',[_vm._v(" It may take a while for the upload to complete, so feel free to go grab a cuppa while you wait. ")]),_c('div',{staticClass:"my-6 d-flex align-center gap-4"},[_c('v-progress-linear',{attrs:{"value":_vm.deleteProgress,"rounded":""}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":_vm.deleteProgress < 100},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Done ")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }