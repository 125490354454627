






























import { Component, Prop, Vue } from 'vue-property-decorator';

import PropertyCard from '@/components/property/PropertyCard.vue';
import SiteCard from '@/components/property/SiteCard.vue';
import StatTile from '@/components/common/StatTile.vue';

import PropertyStats from '@/components/property/PropertyStats.vue';
import SiteStats from '@/components/property/SiteStats.vue';

import GetStartedSite from '@/components/common/GetStartedSite.vue';
import GetStartedSurvey from '@/components/common/GetStartedSurvey.vue';

import FaunaStatsOverview from '@/components/property/FaunaStatsOverview.vue';
import UserStatsLines from '@/components/property/UserStatsLines.vue';
import FaunaStatsLines from '@/components/property/FaunaStatsLines.vue';
import SiteStatsLines from '@/components/property/SiteStatsLines.vue';
import PropertyMap from '@/components/property/PropertyMap.vue';

import inviteUserDialog from '@/invite-user-dialog';

import authModule from '@/store/Auth';
import propertyModule from '@/store/Property';

import { SurveyProperty, SurveySite } from '@/api';

@Component({
  components: {
    PropertyCard,
    SiteCard,
    PropertyStats,
    SiteStats,
    StatTile,
    GetStartedSite,
    GetStartedSurvey,
    FaunaStatsOverview,
    UserStatsLines,
    FaunaStatsLines,
    SiteStatsLines,
    PropertyMap,
  },
})
export default class PropertyOverview extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get canManageUsers() {
    return (
      (authModule.user && authModule.user.id === this.property.owner.id) ||
      authModule.isAdmin
    );
  }

  selectedSite: SurveySite | null = null;

  async inviteUser() {
    await inviteUserDialog(this.property, null);
  }

  async mounted() {
    propertyModule.getPropertyInvasiveStats(this.property.id as string);
    propertyModule.getPropertySurveyStats(this.property.id as string);
    propertyModule.getPropertyFavourites(this.property.id as string);
  }
}
