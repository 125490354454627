

















































import { Component, Vue, Prop, VModel } from 'vue-property-decorator';
import { FaunaMedia } from '@/api';

@Component({})
export default class FaunaMediaGalleryItem extends Vue {
  @Prop({ required: true }) readonly item: FaunaMedia;

  @VModel() selected: string[];

  get previewStyle() {
    return `background-image: url(${this.item.thumbnail})`;
  }
}
