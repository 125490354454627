var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbox-tags"},[(_vm.userTag)?_c('div',{staticClass:"bbox-tags-item",class:( _obj = {}, _obj[("bbox-tags-item--" + (_vm.bbox.tagStatus))] = _vm.bbox.finalTaggerType === 'user', _obj )},[_c('div',{staticClass:"bbox-tags-item__user"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.userTag ? _vm.userTag.faunaMediaTag.createdBy : null,"highlight-me":true,"name":'small'}})],1),_c('div',{staticClass:"bbox-tags-item__tag"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-tag-outline ")]),_c('div',[_vm._v(" "+_vm._s(_vm.userTag ? _vm.userTag.faunaTag.name : 'No user tag')+" ")]),(
          _vm.resolvedTag &&
            _vm.userTag &&
            _vm.resolvedTag.faunaTag.id === _vm.userTag.faunaTag.id
        )?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(_vm.bbox.tagStatus === 'conflict')?_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v(" mdi-alert-circle ")]):_vm._e()],1),_c('div',{staticClass:"bbox-tags-item__confidence"})]):_vm._e(),_c('div',{staticClass:"bbox-tags-item",class:( _obj$1 = {}, _obj$1[("bbox-tags-item--" + (_vm.bbox.tagStatus))] = _vm.bbox.finalTaggerType === 'ai', _obj$1 )},[_c('div',{staticClass:"bbox-tags-item__user"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":null,"stickybeak":true,"name":'small'},scopedSlots:_vm._u([{key:"name",fn:function(){return [_vm._v(" Stickybeak ")]},proxy:true}])})],1),_c('div',{staticClass:"bbox-tags-item__tag"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.aiTag ? 'mdi-tag-outline' : 'mdi-tag-off-outline')+" ")]),(_vm.aiTag)?_c('div',[_vm._v(" "+_vm._s(_vm.aiTag.faunaTag.name)+" ")]):_c('div',{staticClass:"grey--text text--darken-1"},[_vm._v(" No Stickybeak Tag ")]),(!_vm.aiTag)?_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,2808694154)},[(_vm.bbox)?_c('bbox-classification-data',{attrs:{"bbox":_vm.bbox}}):_vm._e()],1):_vm._e(),(
          _vm.resolvedTag &&
            _vm.aiTag &&
            _vm.resolvedTag.faunaTag.id === _vm.aiTag.faunaTag.id
        )?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(_vm.aiTag && _vm.bbox.tagStatus === 'conflict')?_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v(" mdi-alert-circle ")]):_vm._e()],1),_c('div',{staticClass:"bbox-tags-item__confidence"},[(_vm.aiTag && _vm.aiConfidence)?[_vm._v(" "+_vm._s(_vm.aiConfidence.toFixed(2))+"% "),_c('div',{staticClass:"bbox-tags-item__confidence-bar"},[_c('div',{staticClass:"bbox-tags-item__confidence-fill",style:(("width: " + _vm.aiConfidence + "%;"))})])]:_vm._e()],2)]),(_vm.bbox.tagStatus === 'conflict')?_c('div',{staticClass:"bbox-tags-item bbox-tags-item--conflict"},[_c('div',{staticClass:"bbox-tags-item__user"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"expert":true,"name":"small"},scopedSlots:_vm._u([{key:"name",fn:function(){return [_vm._v("Expert opinion required")]},proxy:true}],null,false,1008437901)})],1)]):(_vm.expertTag)?_c('div',{staticClass:"bbox-tags-item",class:( _obj$2 = {}, _obj$2[("bbox-tags-item--" + (_vm.bbox.tagStatus))] = _vm.bbox.finalTaggerType === 'expert', _obj$2 )},[_c('div',{staticClass:"bbox-tags-item__user"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.expertTag ? _vm.expertTag.faunaMediaTag.createdBy : null,"highlight-me":true,"name":'small'},scopedSlots:_vm._u([{key:"name",fn:function(ref){
    var user = ref.user;
return [(user)?_c('span',[_c('strong',[_vm._v(_vm._s(user.name))])]):_c('span',[_vm._v(" Expert ")]),_c('v-icon',{staticClass:"ml-1",attrs:{"color":"primary darken-1","small":""}},[_vm._v(" mdi-school ")])]}}])})],1),_c('div',{staticClass:"bbox-tags-item__tag"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-tag-outline ")]),_c('div',[_vm._v(" "+_vm._s(_vm.expertTag ? _vm.expertTag.faunaTag.name : 'No expert tag')+" ")]),(
          _vm.resolvedTag &&
            _vm.expertTag &&
            _vm.resolvedTag.faunaTag.id === _vm.expertTag.faunaTag.id
        )?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(_vm.bbox.tagStatus === 'conflict')?_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v(" mdi-alert-circle ")]):_vm._e()],1),_c('div',{staticClass:"bbox-tags-item__confidence"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }