











































import { Component, Vue } from 'vue-property-decorator';
import CardInfo from '@/components/common/CardInfo.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import authModule from '@/store/Auth';

@Component({
  components: {
    UserAvatar,
    CardInfo,
  },
})
export default class AccountCard extends Vue {
  get user() {
    if (!authModule.user) {
      throw new Error('User not set');
    }
    return authModule.user;
  }

  get avatarStyle() {
    return this.user ? `background-image: url(${this.user.avatarUrl})` : '';
  }

  get roles() {
    return this.user ? this.user.roles : [];
  }

  async changePrivacy() {
    authModule.setShowWelcomeDialog(true);
  }
}
