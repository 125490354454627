





























































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class SortButton extends Vue {
  @Prop({ default: false }) readonly dark: string;

  @Prop({ required: true }) readonly paramName: string;

  @Prop({ required: true }) readonly items: Array<{
    label: string;
    value: string;
  }>;

  @Prop({ default: '' }) readonly tooltip: string;

  @Prop({ default: false }) readonly multiple: boolean;

  showMenu = false; // menu

  get buttonColor() {
    if (this.dark) {
      return 'grey darken-4 white--text';
    }
    return 'white primary--text';
  }

  get asc() {
    const sort = this.$route.query[this.paramName];
    if (typeof sort !== 'string') {
      return false;
    }
    return !sort.startsWith('-');
  }

  set asc(v: boolean) {
    let sort = this.$route.query[this.paramName];
    if (typeof sort !== 'string') {
      sort = this.items[0].value;
    }
    if (v) {
      sort = sort.replace(/^-/, '');
    } else {
      sort = `-${sort}`;
    }
    this.$router.replace({
      query: {
        ...this.$route.query,
        [this.paramName]: sort,
        page: undefined,
      },
    });
  }

  get value() {
    let sort = this.$route.query[this.paramName];
    if (typeof sort !== 'string') {
      sort = this.items[0].value;
    }
    return sort.replace(/^-/, '');
  }

  set value(v: string) {
    this.$router.replace({
      query: {
        ...this.$route.query,
        [this.paramName]: v,
        page: undefined,
      },
    });
  }

  get label() {
    const currentItem =
      this.items.find(item => item.value === this.value) || this.items[0];
    return currentItem.label;
  }

  setValue(value: string) {
    if (value === this.value) {
      this.asc = !this.asc;
    } else {
      this.value = value;
    }
  }
}
