import { Model, Attr, BelongsTo, HasOne } from 'spraypaint';
import { User, Media } from '@/api';
import dayjs from 'dayjs';

import ApplicationRecord from '../ApplicationRecord';
import SurveySite from './SurveySite';

export enum FaunaSurveyStatus {
  draft = 'draft',
  published = 'published',
  inProgress = 'in_progress',
  assessed = 'assessed',
  verified = 'verified',
  unknown = 'unknown',
}

export const faunaSurveyStatusItems = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Published',
    value: 'published',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Assessed',
    value: 'assessed',
  },
  {
    label: 'Verified',
    value: 'verified',
  },
];

@Model()
export default class FaunaSurvey extends ApplicationRecord {
  static jsonapiType = 'fauna-surveys';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Site',
        value: 'surveySiteName',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return FaunaSurvey.includes([]);
  }

  @Attr() createdAt: string;

  @Attr() startTimestamp: string;

  @Attr() endTimestamp: string;

  @Attr() status: FaunaSurveyStatus;

  // no longer a thing
  // @HasOne() verifiedBy: User;

  @HasOne() assessedBy: User;

  @HasOne() createdBy: User;

  // aggregate counts for detection bbox tag status
  @Attr() readonly tagStatusNone: number;

  @Attr() readonly tagStatusConflict: number;

  @Attr() readonly tagStatusAgreed: number;

  @Attr() readonly tagStatusResolved: number;

  // aggregate counts for detection bbox tag status
  @Attr() readonly snsStatusNone: number;

  @Attr() readonly snsStatusPending: number;

  @Attr() readonly snsStatusUploaded: number;

  @Attr() readonly snsStatusDetected: number;

  @Attr() readonly snsStatusDetectFailed: number;

  @Attr() readonly snsStatusPredicted: number;

  @Attr() readonly snsStatusPredictFailed: number;

  // has no animal bboxes or bboxes is blank true
  @Attr() readonly blankMediaCount: number;

  // does not include pending
  // TODO: check if it includes failed status'
  @Attr() readonly faunaMediaCount: number;

  @BelongsTo() surveySite: SurveySite;

  @HasOne() readonly coverMedia: Media; // do i need this?

  // @HasMany() faunaMedia: FaunaMedia[];

  @Attr() readonly surveySiteName: string;

  get nonBlankMediaCount() {
    return this.faunaMediaCount - this.blankMediaCount;
  }

  get problematicCount() {
    return (
      this.snsStatusPending +
      this.snsStatusDetectFailed +
      this.snsStatusPredictFailed
    );
  }

  get uploadedWithoutErrorsCount() {
    return (
      this.snsStatusNone +
      this.snsStatusUploaded +
      this.snsStatusDetected +
      this.snsStatusPredicted
    );
  }

  // dont include pending!
  get uploadedCount() {
    return (
      this.snsStatusNone +
      // this.snsStatusPending +
      this.snsStatusUploaded +
      this.snsStatusDetected +
      this.snsStatusDetectFailed +
      this.snsStatusPredicted +
      this.snsStatusPredictFailed
    );
  }

  get totalBboxCount() {
    return (
      this.tagStatusNone +
      this.tagStatusConflict +
      this.tagStatusAgreed +
      this.tagStatusResolved
    );
  }

  get totalConflictCount() {
    return this.tagStatusConflict + this.tagStatusResolved;
  }

  get formattedDate() {
    const start = dayjs(this.startTimestamp);
    const end = dayjs(this.endTimestamp);

    if (start.year() === end.year()) {
      return `${start.format('MMMM D')} – ${end.format('MMMM D, YYYY')}`;
    }
    return `${start.format('MMMM D, YYYY')} – ${end.format('MMMM D, YYYY')}`;
  }

  get formattedCreatedDate() {
    return dayjs(this.createdAt).fromNow();
  }

  get prettyStatus() {
    switch (this.status) {
      case FaunaSurveyStatus.draft:
        return 'Draft';
      case FaunaSurveyStatus.published:
        return 'Published';
      case FaunaSurveyStatus.inProgress:
        return 'In progress';
      case FaunaSurveyStatus.assessed:
        return 'Complete';
      default:
        return 'Unknown';
    }
  }

  get createdName() {
    return `${this.createdBy.name || 'Unknown'}`;
  }

  get progressText() {
    switch (this.status) {
      case FaunaSurveyStatus.draft:
        return 'Draft';
      case FaunaSurveyStatus.published:
        return 'Published';
      case FaunaSurveyStatus.inProgress:
        return `Assessment in progress by ${this.assessedBy.name || 'Unknown'}`;
      case FaunaSurveyStatus.assessed:
      case FaunaSurveyStatus.verified:
        return `Assessed by ${this.assessedBy.name || 'Unknown'} (Complete)`;
      default:
        return 'Unknown';
    }
  }
}
