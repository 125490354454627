


















































import { Component, Vue, Prop } from 'vue-property-decorator';

import { SurveyProperty } from '@/api';

import authModule from '@/store/Auth';
import propertyModule from '@/store/Property';

@Component({ components: {} })
export default class SortButton extends Vue {
  showMenu = false; // menu

  items: SurveyProperty[] = [];

  get selected() {
    return this.$route.params.propertyId;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get propertyUsers() {
    return authModule.propertyUsers;
  }

  async mounted() {
    // TODO: use the property module
    if (this.isAdmin) {
      this.items = (
        await SurveyProperty.select(['id', 'name'])
          .per(1000)
          .all()
      ).data;
    } else {
      this.items = (
        await SurveyProperty.where({
          id__in: this.propertyUsers.map(pu => pu.surveyProperty.id),
        })
          .select(['id', 'name'])
          .per(1000)
          .all()
      ).data;
    }
  }
}
