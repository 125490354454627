








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FaunaMedia, FaunaSurveyStatus, UserFavourite } from '@/api';

import LazyImg from '@/components/common/LazyImg.vue';

import authModule from '@/store/Auth';
import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';

import shareDialog from '@/share-dialog';

@Component({
  components: { LazyImg },
})
export default class FaunaMediaThumb extends Vue {
  @Prop({ required: true }) readonly faunaMedia: FaunaMedia;

  favouriteTooltip = false;

  favouritesRequested = false;

  users: string[] = [];

  get faunaMediaTags() {
    return this.faunaMedia.faunaMediaTags.map(ft =>
      cacheModule.faunaTagsById(ft.faunaTag.id as string),
    );
  }

  get userFavourite() {
    return authModule.userFavouriteById(this.faunaMedia.id as string) || null;
  }

  get isDraft() {
    return this.faunaMedia.faunaSurvey.status === FaunaSurveyStatus.draft;
  }

  get isResolved() {
    return (
      this.faunaMedia.tagStatusAgreed + this.faunaMedia.tagStatusResolved ===
      this.faunaMedia.bboxCount
    );
  }

  async getFaunaMediaFavourites() {
    if (!authModule.user || !authModule.user.id) {
      return;
    }
    const fm = (
      await FaunaMedia.includes(['userFavourites.user']).find(
        this.faunaMedia.id as string,
      )
    ).data;
    this.faunaMedia.adjustedFavouriteCount = fm.favouriteCount;
    this.users = fm.userFavourites.map(uf => uf.user.name);
  }

  async share() {
    await shareDialog(this.faunaMedia, this.userFavourite);
    await this.getFaunaMediaFavourites();
    await authModule.getUserFavourites();
  }

  async toggleFavourite() {
    try {
      if (this.userFavourite) {
        await this.userFavourite.destroy();
      } else {
        const userFav = new UserFavourite({
          faunaMedia: this.faunaMedia,
          user: authModule.user,
        });
        await userFav.save({ with: ['faunaMedia.id', 'user.id'] });
      }
      await this.getFaunaMediaFavourites();
      await authModule.getUserFavourites();
      this.$emit('favourite');
    } catch (e) {
      snackModule.setError({
        text: 'Error saving Favourite',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  @Watch('favouriteTooltip')
  favouriteTooltipChanged() {
    if (!this.favouritesRequested) {
      this.getFaunaMediaFavourites();
      this.favouritesRequested = true;
    }
  }

  @Watch('faunaMedia')
  faunaMediaChanged() {
    this.favouritesRequested = false;
  }
}
