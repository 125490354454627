





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/api';

import userModule from '@/store/User';
import authModule from '@/store/Auth';

@Component({
  components: {},
})
export default class UserAvatar extends Vue {
  @Prop({ default: null }) readonly user: User | null;

  @Prop({ default: false }) readonly stickybeak: boolean;

  @Prop({ default: false }) readonly expert: boolean;

  @Prop({ default: 'none' }) readonly name: 'none' | 'small' | 'tooltip';

  @Prop({ default: false }) readonly highlightMe: boolean;

  get storeUser() {
    if (!this.user) {
      return null;
    }
    return userModule.userById(this.user.id as string);
  }

  get displayName() {
    if (this.stickybeak) {
      return 'Stickybeak';
    }
    return this.storeUser ? this.storeUser.name : 'No user found';
  }

  get style() {
    return this.storeUser
      ? `background-image: url(${this.storeUser.avatarUrl});`
      : '';
  }

  get currentUser() {
    return authModule.user;
  }

  get isMe() {
    return (
      this.currentUser && this.user && this.user.id === this.currentUser.id
    );
  }

  mounted() {
    if (this.user) {
      userModule.getUser(this.user.id as string);
    }
  }
}
