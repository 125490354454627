










import { Component, Vue, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';

@Component({
  components: { PageHeader },
})
export default class PropertyListView extends Vue {}
