












































import { User } from '@/api';
import { Component, VModel, Vue, Watch } from 'vue-property-decorator';

import { debounce } from 'lodash';

import snackModule from '@/store/Snack';
import v8n from 'v8n';

@Component({ components: {} })
export default class OwnerAutocomplete extends Vue {
  @VModel() user: User | null;

  search = '';

  loading = false;

  items: User[] = [];

  get validSearchEmail() {
    return v8n()
      .string()
      .not.empty()
      .pattern(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/)
      .test(this.search);
  }

  get userExists() {
    return !!this.items.find(user => user.email === this.search);
  }

  get helperText() {
    return this.validSearchEmail
      ? `Create user&nbsp;<strong>${this.search}</strong>`
      : 'Enter a valid email address to create a new user or select from the list below';
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  test() {
    const user = new User({ email: this.search, roles: [] });
    this.items.push(user);
    this.user = user;
    (this.$refs.select as any).blur();
    // this.search = '';
  }

  async doUpdate() {
    this.loading = true;
    try {
      const result = await User.where({ search: this.search })
        .per(100)
        .all();
      this.items = result.data;
    } catch (e) {
      this.items = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  @Watch('search')
  searchChanged() {
    this.update();
  }
}
