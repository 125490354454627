


























































import { FaunaSurvey, faunaSurveyStatusItems, SurveyProperty } from '@/api';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import SurveyListItem from '@/components/property/SurveyListItem.vue';
import GetStartedSite from '@/components/common/GetStartedSite.vue';
import GetStartedSurvey from '@/components/common/GetStartedSurvey.vue';
import SidebarTiles from '@/components/common/SidebarTiles.vue';
import SidebarTasks from '@/components/common/SidebarTasks.vue';
import FilterButtonContainer from '@/components/common/FilterButtonContainer.vue';
import FilterButton from '@/components/common/FilterButton.vue';

import snackModule from '@/store/Snack';
import cacheModule from '@/store/Cache';
import propertyModule from '@/store/Property';

import { debounce } from 'lodash';
import deleteDialog from '@/delete-dialog';

@Component({
  components: {
    SurveyListItem,
    GetStartedSite,
    GetStartedSurvey,
    FilterButtonContainer,
    FilterButton,
    SidebarTasks,
    SidebarTiles,
  },
})
export default class PropertySurveys extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  loading = false;

  firstLoad = true;

  total = 0;

  surveys: FaunaSurvey[] = [];

  itemsPerPage = 5;

  get showGetStartedSite() {
    return !this.firstLoad && !this.property.surveySites.length;
  }

  get showGetStartedSurvey() {
    return (
      !this.firstLoad &&
      !this.surveys.length &&
      !Object.keys(this.$route.query).length
    );
  }

  get page() {
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  get surveySiteItems() {
    return this.property.surveySites.map(site => ({
      label: site.name,
      value: site.id,
    }));
  }

  get faunaTagItems() {
    return cacheModule.faunaTags.map(ft => ({
      label: ft.name,
      value: ft.id,
    }));
  }

  get filterItems() {
    return [
      {
        label: 'Survey Site',
        relationship: 'survey_site',
        queryParam: 'survey-site',
        items: this.surveySiteItems,
        multiple: false,
      },
      {
        label: 'Status',
        relationship: 'status',
        queryParam: 'status',
        items: faunaSurveyStatusItems,
        multiple: false,
      },
      /*
      {
        label: 'Includes',
        relationship: 'fauna_tag__in',
        queryParam: 'fauna-tag',
        items: this.faunaTagItems,
        multiple: true,
      },
      {
        label: 'Excludes',
        relationship: 'fauna_tag__exclude_in',
        queryParam: 'fauna-tag-exclude',
        items: this.faunaTagItems,
        multiple: true,
      },
      */
    ];
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems.forEach(filterItem => {
      clause[filterItem.relationship] =
        this.$route.query[filterItem.queryParam] || undefined;
    });
    return {
      survey_property: this.property.id,
      ...clause,
    };
  }

  async doUpdate() {
    this.loading = true;
    try {
      const result = await FaunaSurvey.includes(['createdBy', 'assessedBy'])
        .where(this.whereClause)
        .page(this.page)
        .per(this.itemsPerPage)
        .order({ createdAt: 'desc' })
        .all();
      this.surveys = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      this.surveys = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.firstLoad = false;
      this.loading = false;
    }
  }

  async deleteItem(survey: FaunaSurvey) {
    const confirm = await deleteDialog(
      `${survey.id}`,
      'survey',
      `${survey.surveySiteName} ${survey.id}`,
    );
    if (!confirm) {
      return;
    }
    try {
      const item = survey.dup();
      await item.destroy();
      snackModule.setSuccess(`Survey ${item.id} deleted`);
      this.update();
      propertyModule.getPropertySurveyStats(this.property.id as string);
    } catch (e) {
      snackModule.setError({
        text: 'Could not delete',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  mounted() {
    this.update();
  }

  @Watch('page')
  @Watch('whereClause')
  @Watch('property')
  optionsChanged() {
    this.update();
  }
}
