


















































































































import dayjs from 'dayjs';
import { Component, Vue, Watch } from 'vue-property-decorator';

import authModule from '@/store/Auth';
import localeModule from '@/store/Locale';

import MySnackBar from '@/components/common/MySnackBar.vue';
import TermsDialog from '@/components/common/TermsDialog.vue';
import WelcomeDialog from '@/components/common/WelcomeDialog.vue';
import PageFooter from '@/components/common/PageFooter.vue';
import { Route } from 'vue-router';

import Cookies from 'js-cookie';

@Component({
  components: {
    MySnackBar,
    TermsDialog,
    WelcomeDialog,
    PageFooter,
  },
})
export default class App extends Vue {
  tabItems = [
    {
      title: 'Home',
      to: {
        name: 'home',
      },
      exact: true,
      show: () => true,
    },
    {
      title: 'Getting Started',
      to: {
        name: 'dashboard',
      },
      exact: false,
      show: () => this.isLoggedIn,
    },
    {
      title: 'Properties',
      to: {
        name: 'property-list',
      },
      exact: false,
      show: () => this.isLoggedIn && !this.isAdmin,
    },
    {
      title: 'Get Involved',
      to: {
        name: 'register',
      },
      exact: true,
      show: () => !this.isLoggedIn,
    },
    {
      title: 'FAQ',
      to: {
        name: 'faq',
      },
      exact: true,
      show: () => true,
    },
    {
      title: 'Admin Dashboard',
      to: {
        name: 'admin-dashboard-properties',
      },
      exact: false,
      show: () => this.isAdmin,
    },
    {
      title: 'Admin',
      to: {
        name: 'admin-home',
      },
      exact: false,
      show: () => this.isAdmin,
    },
    {
      title: 'Login',
      to: {
        name: 'login',
      },
      exact: true,
      show: () => !this.isLoggedIn,
    },
  ];

  get isLoggedIn() {
    return authModule.isLoggedIn;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get showWelcomeDialog() {
    return authModule.showWelcomeDialog;
  }

  set showWelcomeDialog(v: boolean) {
    authModule.setShowWelcomeDialog(v);
  }

  get termsAgreed() {
    return authModule.termsAgreed;
  }

  get filteredTabs() {
    return this.tabItems.filter(tabItem => tabItem.show());
  }

  get ready() {
    return authModule.ready;
  }

  get canAccessRoute() {
    return authModule.canAccessRoute;
  }

  get userAvatar() {
    if (authModule.user) {
      return authModule.user.avatarUrl;
    }
    return '';
  }

  get showAppBar() {
    return (
      this.$route.matched &&
      this.$route.matched[0] &&
      this.$route.matched[0].meta &&
      !!this.$route.matched[0].meta.showAppBar
    );
  }

  get showNavBar() {
    return (
      this.$route.matched &&
      this.$route.matched[0] &&
      this.$route.matched[0].meta &&
      !!this.$route.matched[0].meta.showNavBar
    );
  }

  get locale() {
    return localeModule.locale;
  }

  get version() {
    return `Version: ${process.env.VUE_APP_VERSION}`;
  }

  get hash() {
    return `Commit: ${process.env.VUE_APP_COMMIT}`;
  }

  logout() {
    authModule.logout();
  }

  @Watch('locale')
  localeChanged() {
    dayjs.locale(this.locale);
  }

  @Watch('$route', { immediate: true })
  routeChange(to: Route /* from: Route */) {
    document.title =
      to.meta && to.meta.title
        ? `${to.meta.title} - Wildtracker`
        : 'Wildtracker';

    // authModule.trackPageview({
    //   gtag: this.$gtag,
    //   route: to,
    //   pageTitle: to.meta.title,
    // });
  }

  @Watch('termsAgreed')
  termsAgreedChanged() {
    if (this.termsAgreed) {
      if (Cookies.get('wt-profile')) {
        return;
      }
      this.showWelcomeDialog = !!authModule.user && !authModule.user.name;
    }
  }
}
