var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"min-width":"380","max-width":"480"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.bbox)?_c('v-card',{staticClass:"bbox-dialog form-vertical-gap"},[_c('div',{staticClass:"bbox-dialog-title mb-6",class:("bbox-dialog-title--" + (_vm.bbox.tagStatus))},[(_vm.titleIcon)?_c('v-icon',{staticClass:"mb-2",attrs:{"large":""}},[_vm._v(_vm._s(_vm.titleIcon))]):_vm._e(),_c('div',{staticClass:"h4"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),(_vm.userTag)?_c('div',{staticClass:"bbox-dialog-row"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.userTag.faunaMediaTag.createdBy,"name":'small',"highlight-me":true},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var user = ref.user;
return [(user)?_c('span',[_c('h5',[_c('strong',[_vm._v(_vm._s(user.name))]),_vm._v(" chose: ")])]):_vm._e()]}}],null,false,3020552355)}),_c('div',{staticClass:"bbox-dialog-item",class:( _obj = {}, _obj[("tag-status--" + (_vm.bbox.tagStatus))] = _vm.bbox.finalTaggerType === 'user', _obj )},[_c('v-icon',{attrs:{"color":"black","small":""}},[_vm._v("mdi-tag-outline")]),_c('strong',[_vm._v(_vm._s(_vm.userTag.faunaTag.name))]),(
            _vm.resolvedTag && _vm.resolvedTag.faunaTag.id === _vm.userTag.faunaTag.id
          )?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(_vm.bbox.tagStatus === 'conflict')?_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v(" mdi-alert-circle ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteTag(_vm.userTag.faunaMediaTag)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)],1):_vm._e(),(_vm.aiTag)?_c('div',{staticClass:"bbox-dialog-row"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"stickybeak":true,"name":'small',"highlight-me":true},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('h5',[_c('strong',[_vm._v("Stickybeak")]),_vm._v(" chose:")])]},proxy:true}],null,false,2699980106)}),_c('div',{staticClass:"bbox-dialog-item",class:( _obj$1 = {}, _obj$1[("tag-status--" + (_vm.bbox.tagStatus))] = _vm.bbox.finalTaggerType === 'ai', _obj$1 )},[_c('v-icon',{attrs:{"color":"black","small":""}},[_vm._v("mdi-tag-outline")]),_c('strong',[_vm._v(_vm._s(_vm.aiTag.faunaTag.name))]),(_vm.resolvedTag && _vm.resolvedTag.faunaTag.id === _vm.aiTag.faunaTag.id)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e(),(
            _vm.bbox.tagStatus === 'conflict' ||
              (_vm.resolvedTag && _vm.resolvedTag.faunaTag.id !== _vm.aiTag.faunaTag.id)
          )?_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v(" mdi-alert-circle ")]):_vm._e(),_c('v-spacer'),_c('div',[_vm._v(_vm._s(_vm.aiConfidence.toFixed(2))+"%")]),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,238531693)},[(_vm.bbox)?_c('bbox-classification-data',{attrs:{"bbox":_vm.bbox}}):_vm._e()],1)],1)],1):_c('div',{staticClass:"bbox-dialog-row"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"stickybeak":true,"name":'small',"highlight-me":true},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('div',{staticClass:"d-flex align-center gap-2"},[_c('h5',[_c('strong',[_vm._v("Stickybeak")]),_vm._v(" tag missing")]),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,238531693)},[(_vm.bbox)?_c('bbox-classification-data',{attrs:{"bbox":_vm.bbox}}):_vm._e()],1)],1)]},proxy:true}],null,false,3729248454)})],1),(_vm.expertTag)?_c('div',{staticClass:"bbox-dialog-row"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.expertTag.faunaMediaTag.createdBy,"name":'small',"highlight-me":true},scopedSlots:_vm._u([{key:"name",fn:function(ref){
          var user = ref.user;
return [(user)?_c('span',[_c('h5',[_c('strong',[_vm._v(_vm._s(user.name))]),_c('v-icon',{staticClass:"ml-1 mr-1",attrs:{"color":"primary darken-1"}},[_vm._v(" mdi-school ")]),_vm._v(" chose: ")],1)]):_vm._e()]}}],null,false,210391340)}),_c('div',{staticClass:"bbox-dialog-item",class:( _obj$2 = {}, _obj$2[("tag-status--" + (_vm.bbox.tagStatus))] = _vm.bbox.finalTaggerType === 'expert', _obj$2 )},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-tag-outline")]),_c('strong',[_vm._v(_vm._s(_vm.expertTag.faunaTag.name))]),(
            _vm.resolvedTag && _vm.resolvedTag.faunaTag.id === _vm.expertTag.faunaTag.id
          )?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteTag(_vm.expertTag.faunaMediaTag)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"bbox-dialog-buttons"},[(_vm.showReviewBtn)?_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","depressed":"","large":"","color":"primary"},on:{"click":_vm.deleteUserTag}},[_vm._v(" I would like to review my decision ")]):_vm._e(),(_vm.showReviewBtn && !_vm.bbox.resolvedTag)?_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","depressed":"","large":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Ask an expert ")]):_vm._e(),(!_vm.showReviewBtn)?_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","depressed":"","large":"","color":"primary"},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Done ")]):_vm._e()],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }