var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbox",class:( _obj = {}, _obj[_vm.bboxStatus] = true, _obj['bbox--active'] =  _vm.active, _obj ),style:(_vm.styles.outerStyle)},[_c('div',{staticClass:"bbox__mask",style:(_vm.styles.maskStyle)}),_c('div',{staticClass:"bbox__outline",style:(_vm.styles.outlineStyle),on:{"click":_vm.onClick}},[_c('div',{staticClass:"bbox__outline-id"},[_c('div',[_vm._v("#"+_vm._s(_vm.bbox.id))]),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-creation")])],1),(_vm.userTag || _vm.expertTag)?_c('div',{staticClass:"bbox__outline-info",style:(_vm.styles.infoStyle)},[(
          _vm.aiTag &&
            _vm.resolvedTag &&
            _vm.aiTag.faunaTag.id === _vm.resolvedTag.faunaTag.id
        )?_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"stickybeak":true}}):_vm._e(),(
          (_vm.userTag &&
            _vm.resolvedTag &&
            _vm.userTag.faunaTag.id === _vm.resolvedTag.faunaTag.id) ||
            (_vm.userTag && !_vm.resolvedTag)
        )?_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.userTag.faunaMediaTag.createdBy,"highlight-me":true}}):_vm._e(),(_vm.expertTag)?_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.expertTag.faunaMediaTag.createdBy,"highlight-me":true}}):_vm._e(),(_vm.resolvedTag)?_c('div',[_vm._v(_vm._s(_vm.resolvedTag.faunaTag.name))]):(_vm.userTag)?_c('div',{staticClass:"d-flex align-center gap-2"},[_vm._v(" "+_vm._s(_vm.userTag.faunaTag.name)+" "),_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v("mdi-alert-circle")])],1):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }