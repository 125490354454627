
























import { Component, Vue, Prop } from 'vue-property-decorator';
import CardInfo from '@/components/common/CardInfo.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';

import { SurveySite } from '@/api';

@Component({
  components: {
    UserAvatar,
    CardInfo,
  },
})
export default class SiteCard extends Vue {
  @Prop({ required: true }) readonly site: SurveySite;
}
