var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-container"},[(_vm.property)?_c('div',{staticClass:"breadcrumb-item"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.property.owner}}),(_vm.property)?_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'property-overview',
        params: { propertyId: _vm.property.id },
      }}},[_vm._v(" "+_vm._s(_vm.property.name)+" ")]):_vm._e()],1):_vm._e(),(_vm.site)?_c('div',{staticClass:"breadcrumb-item"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-arrow-right ")]),_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'property-sites',
        params: { propertyId: _vm.site.id },
      }}},[_vm._v(" "+_vm._s(_vm.site.name)+" ")])],1):_vm._e(),(_vm.property && _vm.survey)?_c('div',{staticClass:"breadcrumb-item"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-arrow-right ")]),_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'classifier',
        params: { propertyId: _vm.property.id, faunaSurveyId: _vm.survey.id },
      }}},[_vm._v(" Survey #"+_vm._s(_vm.survey.id)+" ")])],1):_vm._e(),(_vm.faunaMedia)?_c('div',{staticClass:"breadcrumb-item"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-arrow-right ")]),_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'classifier',
      }}},[_vm._v(" Image #"+_vm._s(_vm.faunaMedia.id)+" ")])],1):_vm._e(),(_vm.bbox)?_c('div',{staticClass:"breadcrumb-item"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-arrow-right ")]),_c('router-link',{staticClass:"breadcrumb",attrs:{"to":{
        name: 'classifier',
      }}},[_vm._v(" Detection #"+_vm._s(_vm.bbox.id)+" ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }