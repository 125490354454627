















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ImageZipPreview extends Vue {
  @Prop({ required: true }) readonly images: string[];
}
