


















import { Component, Vue, Prop } from 'vue-property-decorator';

import { DetectionBbox } from '@/api';

import UserAvatar from '@/components/common/UserAvatar.vue';

@Component({
  components: {
    UserAvatar,
  },
})
export default class BboxDialog extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;
}
