


















































































import {
  Component,
  Prop,
  Vue,
  Watch,
  ProvideReactive,
} from 'vue-property-decorator';

import {
  SurveyProperty,
  FaunaMedia,
  DiseaseStatus,
  FaunaSurveyStatus,
  FaunaSurvey,
} from '@/api';

import FaunaMediaThumb from '@/components/common/FaunaMediaThumb.vue';
import FilterButton from '@/components/common/FilterButton.vue';

import ClassifierNav from '@/components/classifier/ClassifierNav.vue';
import ClassifierImage from '@/components/classifier/ClassifierImage.vue';
import ClassifierImageInfo from '@/components/classifier/ClassifierImageInfo.vue';

import authModule from '@/store/Auth';
import propertyModule from '@/store/Property';
import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';

import confirmDialog from '@/confirm-dialog';
import { debounce, isEqual } from 'lodash';
import { ConfidenceLevel } from '@/api/models/FaunaMediaTag';

const debounceDelay = 300;

@Component({
  components: {
    FaunaMediaThumb,
    FilterButton,
    ClassifierNav,
    ClassifierImage,
    ClassifierImageInfo,
  },
})
export default class Classifier extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get faunaSurveyId() {
    const id = this.$route.params.faunaSurveyId;
    if (Number.isNaN(parseInt(id, 10))) {
      throw new Error('Invalid fauna survey id');
    }
    return id;
  }

  faunaSurvey: FaunaSurvey | null = null;

  loading = false;

  // for nav
  // a pageful of fm
  faunaMedia: FaunaMedia[] = [];

  // for nav
  // total fm count
  total = 0;

  // for nav
  // how many items to show per page
  itemsPerPage = 30;

  // for nav
  // the array index of the selected item
  selected = -1;

  // the active item
  // purposefully detached from a getter
  activeItem: FaunaMedia | null = null;

  showFilters = false;

  @ProvideReactive() classifier: Classifier | null = null;

  // the selected item getter
  get selectedItem() {
    return this.faunaMedia[this.selected];
  }

  // current status of the survey
  get faunaSurveyStatus() {
    return this.faunaSurvey
      ? this.faunaSurvey.status
      : FaunaSurveyStatus.unknown;
  }

  get faunaSurveyPrettyStatus() {
    return this.faunaSurvey ? this.faunaSurvey.prettyStatus : 'Unknown';
  }

  get isDraft() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.draft;
  }

  get isPublished() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.published;
  }

  get isInProgress() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.inProgress;
  }

  get isComplete() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.assessed;
  }

  // who am i?
  get isPropertyOwner() {
    return authModule.user && authModule.user.id === this.property.owner.id;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isSurveyCreator() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.createdBy &&
      authModule.user &&
      authModule.user.id === this.faunaSurvey.createdBy.id
    );
  }

  get isSurveyAssessor() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.assessedBy &&
      authModule.user &&
      authModule.user.id === this.faunaSurvey.assessedBy.id
    );
  }

  get canPublish() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.draft &&
      this.total > 0 &&
      (this.isSurveyCreator || this.isPropertyOwner || this.isAdmin)
    );
  }

  // what can I do?
  get canEdit() {
    return (
      this.isAdmin ||
      (this.faunaSurveyStatus === FaunaSurveyStatus.inProgress &&
        (this.isSurveyAssessor || this.isPropertyOwner))
    );
  }

  get canStartTagging() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.published &&
      authModule.user &&
      authModule.canAssessSurveys(this.property)
    );
  }

  get canComplete() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.inProgress &&
      (this.isPropertyOwner || this.isSurveyAssessor || this.isAdmin)
    );
  }

  // used to find the page that an id is on
  get containsId() {
    return (this.$route.query.id as string) || undefined;
  }

  set containsId(id: string | undefined) {
    this.$router.replace({
      query: { ...this.$route.query, id },
    });
  }

  // the current page
  get page() {
    if (this.containsId) {
      return -1;
    }
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, id: undefined, page: p.toString() },
    });
  }

  // debounced function to set the active item
  get setActiveItem() {
    return debounce(this.doSetActiveItem, debounceDelay);
  }

  // debounced function to update the fm
  get update() {
    return debounce(this.updateFm, debounceDelay);
  }

  get mediaFactory() {
    // TODO: eventually we wont want to include fm tags
    // TODO: do I need the fauna survey?
    return () => FaunaMedia.includes(['faunaSurvey', 'faunaMediaTags']);
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems
      .filter(filterItem => !!filterItem.relationship)
      .forEach(filterItem => {
        clause[filterItem.relationship] =
          this.$route.query[filterItem.queryParam] || undefined;
      });

    return {
      fauna_survey: this.faunaSurveyId || undefined,
      survey_property: this.faunaSurveyId ? undefined : this.property.id,
      ...clause,
    };
  }

  get hasFilters() {
    return !!this.filterItems.filter(
      filterItem =>
        !!filterItem.relationship &&
        this.$route.query[filterItem.queryParam] !== undefined,
    ).length;
  }

  get surveySiteItems() {
    return this.property.surveySites.map(site => ({
      label: site.name,
      value: site.id,
    }));
  }

  get filterItems() {
    const siteFilter = {
      label: 'Site',
      relationship: 'surveySite',
      queryParam: 'survey-site',
      items: this.surveySiteItems,
      multiple: false,
    };

    const remainingFilters = [
      {
        label: 'Has Tags',
        relationship: 'has_tags',
        queryParam: 'has-tags',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Tagged by',
        relationship: 'tagged_by__in',
        queryParam: 'tagged-by',
        items: propertyModule
          .propertyUsers(this.property.id as string)
          .map(user => ({ label: user.name, value: user.id })),
        multiple: true,
      },
      {
        label: 'Has Favourites',
        relationship: 'has_favourites',
        queryParam: 'has-favourites',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Favourited by',
        relationship: 'favourited_by__in',
        queryParam: 'favourited-by',
        items: propertyModule
          .propertyUsers(this.property.id as string)
          .map(user => ({ label: user.name, value: user.id })),
        multiple: true,
      },
      {
        label: 'Has Comment',
        relationship: 'has_comment',
        queryParam: 'has-comment',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Includes',
        relationship: 'fauna_tag__in',
        queryParam: 'fauna-tag',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'Excludes',
        relationship: 'fauna_tag__exclude_in',
        queryParam: 'fauna-tag-exclude',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'Images',
        relationship: 'is_blank',
        queryParam: 'is-blank',
        items: [
          {
            label: 'Blanks',
            value: 'true',
          },
          {
            label: 'Non Blanks',
            value: 'false',
          },
        ],
        multiple: false,
      },
    ];

    return this.faunaSurveyId
      ? remainingFilters
      : [siteFilter, ...remainingFilters];
  }

  doSetActiveItem() {
    this.activeItem = this.selectedItem;
  }

  nextImage() {
    const index = this.faunaMedia.findIndex(fm => fm.tagStatusNone > 0);
    if (index !== -1) {
      this.selected = index;
    }
    // TODO jump to next page if none found?
  }

  async refreshFaunaMedia(faunaMediaId: string) {
    try {
      const faunaMedia = (await this.mediaFactory().find(faunaMediaId)).data;
      const inx = this.faunaMedia.findIndex(item => item.id === faunaMediaId);
      if (inx !== -1) {
        this.faunaMedia.splice(inx, 1, faunaMedia);
      }
    } catch (e) {
      // not found
    }
  }

  async updateFm() {
    try {
      this.loading = true;
      const result = await this.mediaFactory()
        .where(this.whereClause)
        .extraParams({
          page: {
            contains_id: this.containsId ? this.containsId : undefined,
            number: this.containsId ? undefined : this.page,
            size: this.itemsPerPage,
          },
        })
        .per(this.itemsPerPage)
        .order({ timestamp: 'asc' })
        .all();

      this.total = result.meta.pagination.count;
      this.faunaMedia = result.data;
    } catch (e) {
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  // TODO: handle contains id
  /*
  async doUpdate() {
    // TODO: currently you lose your spot with this method!
    // it was first there to ensure everything was synced up
    // not sure if I need to do that anymore
    this.loading = true;
    this.selected = -1;
    this.faunaMedia = [];
    try {
      const result = await this.mediaFactory()
        .where(this.whereClause)
        .extraParams({
          page: {
            contains_id: this.containsId ? this.containsId : undefined,
            number: this.containsId ? undefined : this.page,
            size: this.itemsPerPage,
          },
        })
        .per(this.itemsPerPage)
        .order({ timestamp: 'asc' })
        .all();

      this.faunaMedia = result.data;
      this.total = result.meta.pagination.count;

      // select the fauna media with the correct id
      let index = -1;
      if (this.containsId) {
        index = this.faunaMedia.findIndex(fm => fm.id === this.containsId);
      }
      this.selected = index === -1 ? 0 : index;

      // set the correct page according to returned query
      if (this.page !== result.meta.pagination.page) {
        this.page = result.meta.pagination.page;
      }
    } catch (e) {
      this.faunaMedia = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
  */

  async getFaunaSurvey() {
    if (this.faunaSurveyId) {
      this.faunaSurvey = (
        await FaunaSurvey.includes(['createdBy', 'assessedBy']).find(
          this.faunaSurveyId,
        )
      ).data;
    }
  }

  async confidenceLevelDialog() {
    return confirmDialog({
      title: 'Confidence Level',
      description: 'On a scale of 1 - 5, how confident are you?',
      block: true,
      buttons: [
        {
          key: ConfidenceLevel.very_low,
          title: '1',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.low,
          title: '2',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.medium,
          title: '3',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.high,
          title: '4',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.very_high,
          title: '5',
          color: 'grey',
          outlined: true,
        },
      ],
    });
  }

  async diseaseStatusDialog() {
    return confirmDialog({
      title: 'Disease Status',
      description:
        'Please indicate whether or not you can identify disease present for this tag',
      block: true,
      buttons: [
        {
          key: DiseaseStatus.diseased,
          title: 'Diseased',
          color: 'red white--text',
          outlined: false,
        },
        {
          key: DiseaseStatus.healthy,
          title: 'Healthy',
          color: 'green white--text',
          outlined: false,
        },
        {
          key: DiseaseStatus.unsure,
          title: 'Unsure',
          color: 'grey',
          outlined: true,
        },
      ],
    });
  }

  async publish() {
    if (this.loading || !this.faunaSurvey || !authModule.user) {
      return;
    }
    const selection = await confirmDialog({
      title: 'Publish survey?',
      description:
        "Once published, the survey will become available for tagging. Please make sure you've uploaded all of your photos before continuing.",
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: 'Publish',
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.published;
      await this.faunaSurvey.save();
    } finally {
      this.loading = false;
    }
  }

  async startTagging() {
    if (this.loading || !this.faunaSurvey || !authModule.user) {
      return;
    }
    const selection = await confirmDialog({
      title: 'Start Tagging',
      description:
        'You will be able to stop and return to tagging images from this survey at your own leisure. Just be sure not to mark the survey as complete until all images are tagged!',
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: "Let's start",
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.inProgress;
      this.faunaSurvey.assessedBy = authModule.user;
      await this.faunaSurvey.save({ with: 'assessedBy.id' });
      this.getFaunaSurvey();
    } finally {
      this.loading = false;
    }
  }

  // TODO: move this to bbox level
  /*
  async setConfidenceLevel(fmTag: FaunaMediaTag) {
    const confidenceLevel = await this.confidenceLevelDialog();
    if (confidenceLevel === null) {
      return;
    }

    fmTag.confidenceLevel = confidenceLevel as ConfidenceLevel;
    try {
      this.loading = true;
      await fmTag.save();
      this.refreshFaunaMedia(fmTag.faunaMedia.id as string);
      snackModule.setSuccess('Confidence level updated');
    } catch (e) {
      snackModule.setError({
        text: 'Could not set confidence level',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
  */

  async markAsComplete() {
    if (this.loading || !this.faunaSurvey) {
      return;
    }
    const selection = await confirmDialog({
      title: 'All done?',
      description:
        'Are you sure? By clicking confirm you will no longer be able to add or edit any tags in this survey.',
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: "I'm all done",
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.assessed;
      await this.faunaSurvey.save();
      this.getFaunaSurvey();
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    await this.getFaunaSurvey();
    await this.update();
    this.showFilters = this.hasFilters;
  }

  created() {
    // TODO: do something with this!
    this.classifier = this;
  }

  beforeDestroy() {
    this.classifier = null;
  }

  @Watch('page')
  pageChanged(newVal: number, oldVal: number) {
    if (oldVal !== -1) {
      this.update();
    }
  }

  @Watch('whereClause')
  whereChanged(
    newVal: { [key: string]: unknown },
    oldVal: { [key: string]: unknown },
  ) {
    if (!isEqual(newVal, oldVal)) {
      this.update();
    }
  }

  @Watch('selectedItem')
  selectedItemChanged() {
    this.setActiveItem();
  }
}
