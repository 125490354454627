var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"classifier-nav",style:(_vm.gridVWStyle)},[_c('grid-resize',{on:{"delta":_vm.handleGridResize,"end":_vm.handleGridResizeEnd}}),_c('div',{staticClass:"classifier-nav__grid-outer"},[_c('div',{ref:"grid",staticClass:"classifier-nav__grid-inner",style:(_vm.gridColStyle)},_vm._l((_vm.faunaMedia),function(fm,i){return _c('fauna-media-thumb',{key:i,ref:"thumbs",refInFor:true,class:{ classifier__selected: _vm.selected === i },attrs:{"faunaMedia":fm},on:{"click":function($event){_vm.selected = i}}})}),1)]),_c('div',{staticClass:"classifier-nav__pagination ml-2 mr-2 pa-4"},[_c('div',{staticClass:"caption text-center mb-2"},[_vm._v(" "+_vm._s(_vm.displayCount)+" ")]),_c('div',{staticClass:"d-flex justify-center form-horizontal-gap-small"},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":"","color":"white","disabled":_vm.page === 1},on:{"click":function($event){_vm.page = _vm.page - 1}}},on),[_c('v-icon',[_vm._v("mdi-chevron-left ")])],1)]}}])},[_vm._v(" Previous page ")]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":"","color":"white","disabled":_vm.page === _vm.pageCount},on:{"click":function($event){_vm.page = _vm.page + 1}}},on),[_c('v-icon',[_vm._v("mdi-chevron-right ")])],1)]}}])},[_vm._v(" Next page ")]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":"","color":"white"},on:{"click":function($event){return _vm.$emit('refresh')}}},on),[_c('v-icon',[_vm._v("mdi-refresh ")])],1)]}}])},[_vm._v(" Refresh ")]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dark":"","color":"white"},on:{"click":_vm.toggleSmallThumbs}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.smallThumbs ? 'mdi-image-size-select-actual' : 'mdi-image-size-select-large'))])],1)]}}])},[_vm._v(" "+_vm._s(_vm.smallThumbs ? 'Toggle Larger Thumbnails' : 'Toggle Smaller Thumbnails')+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }