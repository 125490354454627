
















































































































































import { Prop, Component, Watch, Vue } from 'vue-property-decorator';
import { SurveyProperty, FaunaSurvey } from '@/api';

import AlertAccordion from '@/components/common/AlertAccordion.vue';

import FaunaMediaUploader from '@/components/upload/FaunaMediaUploader.vue';
import FaunaMediaGallery from '@/components/upload/FaunaMediaGallery.vue';
import FaunaMediaProblemTable from '@/components/upload/FaunaMediaProblemTable.vue';
import FaunaMediaUploadDialog from '@/components/upload/FaunaMediaUploadDialog.vue';
import StatTile from '@/components/common/StatTile.vue';
import StatBtn from '@/components/common/StatBtn.vue';

import dayjs from 'dayjs';
import { numberFormat } from '@/util';

import {
  BatchUploadResult,
  batchUploader,
} from '@/components/upload/batch-uploader';

@Component({
  components: {
    AlertAccordion,
    FaunaMediaUploader,
    FaunaMediaGallery,
    FaunaMediaProblemTable,
    FaunaMediaUploadDialog,
    StatTile,
    StatBtn,
  },
})
export default class SurveyStep2 extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  uploadDialog = false;

  uploader: BatchUploadResult | null = null;

  get numberFormat() {
    return numberFormat;
  }

  /**
   * whether or not can continue to next step
   */
  get valid() {
    return (
      this.survey.faunaMediaCount > 0 &&
      this.survey.snsStatusPredicted === this.survey.faunaMediaCount
    );
  }

  /**
   * how many have been predicted
   */
  get predictedCount() {
    return this.survey ? this.survey.snsStatusPredicted : 0;
  }

  /**
   * how many have been detected
   */
  get detectedCount() {
    return this.survey.snsStatusPredicted + this.survey.snsStatusDetected;
  }

  /**
   * how many photos are in the survey
   */
  get totalCount() {
    return this.survey.faunaMediaCount;
  }

  /**
   * whether or not the survey is still processing
   */
  get isProcessing() {
    return this.predictedCount < this.totalCount;
  }

  // instance for clearing the interval
  predictedCountInterval: number | null = null;

  lastCountTS = '';

  refreshTables() {
    (this.$refs.gallery as FaunaMediaGallery).update();
    (this.$refs.table as FaunaMediaProblemTable).update();
  }

  /**
   * Create a batch uploader to handle the upload of files
   */
  startUpload(files: File[]) {
    // only accept jpegs
    const filteredFiles = Array.from(files).filter(
      f => f.type === 'image/jpeg',
    );

    // start the uploader
    this.uploader = batchUploader(filteredFiles, this.survey.id as string);
    this.uploader.start();
  }

  mounted() {
    // periodically get the predicted count if still processing
    this.predictedCountInterval = setInterval(() => {
      if (this.isProcessing) {
        this.$emit('update');
        this.lastCountTS = dayjs().format('h:mm A');
      }
    }, 5000);
  }

  beforeDestroy() {
    // clear the interval
    if (this.predictedCountInterval) {
      clearInterval(this.predictedCountInterval);
    }
  }

  back() {
    this.$emit('back');
  }

  next() {
    this.$emit('next');
  }

  update() {
    this.$emit('update');
  }

  /**
   * Watch for the close of the upload dialog
   * clear the uploader and emit an update
   */
  @Watch('uploadDialog')
  uploadDialogChanged() {
    if (!this.uploadDialog) {
      this.uploader = null;
      this.$emit('update');
    }
  }
}
