



















































































import { Component, Prop, Vue, VModel } from 'vue-property-decorator';
import { DetectionBbox, FaunaTag } from '@/api';

import cacheModule from '@/store/Cache';

@Component({
  components: {},
})
export default class BBoxContextMenu extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;

  @Prop({ required: true }) readonly x: number;

  @Prop({ required: true }) readonly y: number;

  @VModel() active: boolean;

  windowH = 0;

  windowW = 0;

  /**
   * the menu is positioned above the bbox if the bbox is above the center of the window
   */
  get top() {
    return this.y > this.windowH / 2;
  }

  /**
   * the menu is positioned to the left of the bbox if the bbox is to the left of 3/4 of the window
   */
  get left() {
    return this.x > this.windowW * 0.75;
  }

  /**
   * organise the fauna tags by group
   */
  get faunaTagGroups() {
    return cacheModule.faunaTagGroups.map((group, i) => ({
      active: i === 0,
      group,
      children: cacheModule.faunaTagsByGroupId(group.id as string),
    }));
  }

  /**
   * the first 2 groups are expanded in the menu
   */
  get firstGroups() {
    return this.faunaTagGroups.slice(0, 2);
  }

  /**
   * the last 2 groups are hidden behind a secondary menu
   */
  get lastGroups() {
    return this.faunaTagGroups.slice(2);
  }

  /**
   * handler for selecting a tag
   */
  selectTag(tag: FaunaTag) {
    this.$emit('select-tag', tag, this.bbox);
  }

  /**
   * handler for selecting a blank bbox
   */
  selectBlank() {
    this.$emit('select-blank', this.bbox);
  }

  /**
   * handler for selecting classification data
   */
  selectInfo() {
    this.$emit('select-info', this.bbox);
  }

  /**
   * handle window resize
   */
  onResize() {
    this.windowH = window.innerHeight;
    this.windowW = window.innerWidth;
    this.active = false;
  }

  created() {
    // add resize listener
    window.addEventListener('resize', this.onResize);
  }

  beforeDestroy() {
    // remove resize listener
    window.removeEventListener('resize', this.onResize);
  }

  mounted() {
    // trigger resize handler
    this.onResize();
  }
}
