
























































































































































































































import {
  Component,
  Vue,
  Prop,
  InjectReactive,
  Watch,
} from 'vue-property-decorator';

import {
  FaunaMedia,
  FaunaSurvey,
  FaunaSurveyStatus,
  SurveyProperty,
  UserFavourite,
} from '@/api';

import snackModule from '@/store/Snack';
import authModule from '@/store/Auth';

import UserAvatar from '@/components/common/UserAvatar.vue';
import Classifier from '@/components/classifier/Classifier.vue';

import shareDialog from '@/share-dialog';
import confirmDialog from '@/confirm-dialog';

@Component({
  components: {
    UserAvatar,
  },
})
export default class ClassifierImageInfo extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  @Prop({ required: true }) readonly item: FaunaMedia;

  @InjectReactive() classifier: Classifier;

  loading = false;

  get userFavourite() {
    return this.item && authModule.userFavouriteById(this.item.id as string);
  }

  get statusTitle() {
    switch (this.classifier.faunaSurveyStatus) {
      case FaunaSurveyStatus.draft:
        return 'Draft';
      case FaunaSurveyStatus.published:
        return 'Ready for tagging';
      case FaunaSurveyStatus.inProgress:
        return 'Tagging in progress';
      case FaunaSurveyStatus.assessed:
        return 'Tagging complete';
      default:
        return 'Unknown';
    }
  }

  async getFaunaMediaFavourites() {
    if (!authModule.user || !authModule.user.id) {
      return;
    }
    const fm = (
      await FaunaMedia.includes(['userFavourites.user']).find(
        this.item.id as string,
      )
    ).data;
    this.item.adjustedFavouriteCount = fm.favouriteCount;
  }

  async toggleFavourite() {
    try {
      if (this.userFavourite) {
        await this.userFavourite.destroy();
      } else {
        const userFav = new UserFavourite({
          faunaMedia: this.item,
          user: authModule.user,
        });
        await userFav.save({ with: ['faunaMedia.id', 'user.id'] });
      }
      await Promise.all([
        this.getFaunaMediaFavourites(),
        authModule.getUserFavourites(),
      ]);
      this.$emit('favourite');
    } catch (e) {
      snackModule.setError({
        text: 'Error saving Favourite',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  async toggleShare() {
    await shareDialog(this.item, this.userFavourite);
    this.getFaunaMediaFavourites();
    authModule.getUserFavourites();
  }

  // TODO: these can probably be buttons with disabled + enabled states
}
