




































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FaunaMedia, FaunaSurvey } from '@/api';

import CompactPagination from '@/components/upload/CompactPagination.vue';

import confirmDialog from '@/confirm-dialog';
import snackModule from '@/store/Snack';
import debounce from 'lodash/debounce';

@Component({
  components: {
    CompactPagination,
  },
})
export default class FaunaMediaProblemTable extends Vue {
  @Prop({ required: true }) readonly survey: FaunaSurvey;

  loading = false;

  total = 0;

  page = 1;

  itemsPerPage = 30;

  deleteDialog = false;

  deleteProgress = 0; // 0 - 100

  faunaMedia: FaunaMedia[] = [];

  selected: FaunaMedia[] = [];

  get headers() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Image',
        value: 'filename',
      },
      {
        text: 'Status',
        value: 'snsStatus',
      },
    ];
  }

  get scopeFactory() {
    return () =>
      FaunaMedia.where({
        faunaSurvey: this.survey.id,
        sns_status__in: ['pending', 'detect_failed', 'predict_failed'],
      }).order({ timestamp: 'asc' });
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  async doUpdate() {
    try {
      this.loading = true;
      this.selected = [];

      const result = await this.scopeFactory()
        .per(this.itemsPerPage)
        .page(this.page)
        .all();
      this.faunaMedia = result.data;
      this.total = result.meta.pagination.count;
      this.$emit('update');
    } catch (e) {
      this.faunaMedia = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  selectAll() {
    this.selected = [...this.faunaMedia];
  }

  deselectAll() {
    this.selected = [];
  }

  /**
   * deletes the selected items
   * (maaaany requests not very efficient)
   */
  async deleteSelected() {
    const confirm = await confirmDialog();
    if (confirm !== 'confirm') {
      return;
    }

    const max = this.selected.length;
    let count = 0;

    const promises = this.selected.map(async fm => {
      try {
        const item = new FaunaMedia({ id: fm.id });
        item.isPersisted = true;

        await item.destroy();
        return Promise.resolve();
      } catch (e) {
        snackModule.setError({
          text: 'Error deleting file',
          errors: (e as ErrorResponse).response.errors,
        });
        return Promise.resolve();
      } finally {
        count += 1;
        this.deleteProgress = (100 * count) / max;
      }
    });

    await Promise.all(promises);
    this.update();
  }

  mounted() {
    this.update();
  }

  @Watch('page')
  pageChanged() {
    this.update();
  }

  @Watch('deleteProgress')
  deleteProgressChanged() {
    if (!this.deleteDialog) {
      this.deleteDialog = true;
    }
  }
}
