





















































import { Component, Vue, Watch } from 'vue-property-decorator';

import { debounce } from 'lodash';

import PageHeader from '@/components/common/PageHeader.vue';
import PropertyListItem from '@/components/property/PropertyListItem.vue';
import FilterButtonContainer from '@/components/common/FilterButtonContainer.vue';
import FilterButton from '@/components/common/FilterButton.vue';
import SortButton from '@/components/common/SortButton.vue';

import { SurveyProperty } from '@/api';

import propertyModule from '@/store/Property';
import snackModule from '@/store/Snack';

import deleteDialog from '@/delete-dialog';

@Component({
  components: {
    PageHeader,
    FilterButtonContainer,
    FilterButton,
    SortButton,
    PropertyListItem,
  },
})
export default class PropertyList extends Vue {
  loading = false;

  total = 0;

  properties: SurveyProperty[] = [];

  itemsPerPage = 10;

  get sortItems() {
    return [
      {
        label: 'Created',
        value: 'createdAt',
      },
      {
        label: 'Name',
        value: 'name',
      },
    ];
  }

  get filterItems() {
    return [
      {
        label: 'Survey Site',
        relationship: 'survey_site',
        queryParam: 'survey-site',
        items: [],
        multiple: false,
      },
      {
        label: 'Status',
        relationship: 'status',
        queryParam: 'status',
        items: [],
        multiple: false,
      },
      {
        label: 'Includes',
        relationship: 'fauna_tag__in',
        queryParam: 'fauna-tag',
        items: [],
        multiple: true,
      },
      {
        label: 'Excludes',
        relationship: 'fauna_tag__exclude_in',
        queryParam: 'fauna-tag-exclude',
        items: [],
        multiple: true,
      },
    ];
  }

  get search() {
    return (this.$route.query.search as string) || '';
  }

  set search(s: string) {
    this.$router.replace({
      query: {
        ...this.$route.query,
        search: s,
        page: undefined,
      },
    });
  }

  get page() {
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  get sort(): { [key: string]: 'asc' | 'desc' } {
    if (!this.$route.query.sort) {
      return { createdAt: 'desc' };
    }
    let sort = this.$route.query.sort as string;
    let asc = true;
    if (sort.startsWith('-')) {
      sort = sort.replace(/^-/, '');
      asc = false;
    }
    return { [sort]: asc ? 'asc' : 'desc' };
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  async doUpdate() {
    this.loading = true;
    try {
      const result = await propertyModule.getProperties(
        SurveyProperty.includes(['owner', 'surveySites', 'propertyUsers.user'])
          .where({ search: this.search })
          .order(this.sort)
          .page(this.page)
          .per(this.itemsPerPage),
      );
      this.properties = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      this.properties = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(property: SurveyProperty) {
    const confirm = await deleteDialog(
      property.name,
      'property',
      property.name,
    );
    if (!confirm) {
      return;
    }
    try {
      const item = property.dup();
      await item.destroy();
      snackModule.setSuccess(`${item.name} deleted`);
      this.update();
    } catch (e) {
      snackModule.setError({
        text: 'Could not delete',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  mounted() {
    this.update();
  }

  @Watch('page')
  @Watch('sort')
  @Watch('search')
  optionsChanged() {
    this.update();
  }
}
