import { Model, Attr } from 'spraypaint';

import ApplicationRecord from '../ApplicationRecord';

// this is a POST only model
// fauna survey must have status of draft
// provide list of images and it will return a signature map of presigned urls
// or a blank string if the image already exists

@Model()
export default class FaunaSurveyPresignImages extends ApplicationRecord {
  static jsonapiType = 'fauna-survey-presign-images';

  static listHeaders() {
    return [];
  }

  static scopeFactory() {
    return FaunaSurveyPresignImages.includes([]);
  }

  @Attr() faunaSurvey: number;

  @Attr() images: string[];

  // returns map of { [imageKey]: url || "" }
  @Attr() readonly signatureMap: { [key: string]: string };
}
