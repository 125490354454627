




















































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import { DetectionBbox, TaggerType, FaunaMediaTag } from '@/api';

import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';

import UserAvatar from '@/components/common/UserAvatar.vue';
import BboxClassificationData from '@/components/classifier/BboxClassificationData.vue';

@Component({
  components: {
    UserAvatar,
    BboxClassificationData,
  },
})
export default class BboxTags extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;

  loading = false;

  get userTag() {
    return this.bbox.userTag
      ? {
          faunaMediaTag: this.bbox.userTag,
          faunaTag: cacheModule.faunaTagsById(
            this.bbox.userTag.faunaTag.id as string,
          ),
        }
      : null;
  }

  get aiTag() {
    return this.bbox.aiTag
      ? {
          faunaMediaTag: this.bbox.aiTag,
          faunaTag: cacheModule.faunaTagsById(
            this.bbox.aiTag.faunaTag.id as string,
          ),
        }
      : null;
  }

  get expertTag() {
    return this.bbox.expertTag
      ? {
          faunaMediaTag: this.bbox.expertTag,
          faunaTag: cacheModule.faunaTagsById(
            this.bbox.expertTag.faunaTag.id as string,
          ),
        }
      : null;
  }

  get resolvedTag() {
    return this.bbox.resolvedTag
      ? {
          faunaMediaTag: this.bbox.resolvedTag,
          faunaTag: cacheModule.faunaTagsById(
            this.bbox.resolvedTag.faunaTag.id as string,
          ),
        }
      : null;
  }

  get aiConfidence() {
    return this.bbox ? this.bbox.aiConfidence * 100 : 0;
  }
}
