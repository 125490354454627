




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DetectionBbox, TagStatusType } from '@/api';

@Component({})
export default class BboxStatus extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;

  get bboxClass() {
    return `bbox-status--${this.bbox.tagStatus}`;
  }

  get title() {
    return this.bbox.tagStatus;
  }
}
