





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class AlertAccordion extends Vue {
  @Prop({ default: 'none' }) readonly icon: 'left' | 'right' | 'none';

  @Prop({ default: false }) readonly disabled: boolean;

  isOpen = false;

  toggle(val: boolean) {
    (this.$el as HTMLDetailsElement).open = val;
  }

  nativeToggleHandler() {
    this.isOpen = (this.$el as HTMLDetailsElement).open;
  }

  handleClick(e: Event) {
    if (this.disabled) {
      e.preventDefault();
    }
  }

  beforeDestroy() {
    this.$el.removeEventListener('toggle', this.nativeToggleHandler);
  }

  mounted() {
    this.nativeToggleHandler();
    this.$el.addEventListener('toggle', this.nativeToggleHandler);
  }

  @Watch('isOpen')
  isOpenChanged() {
    this.$emit('toggle', this.isOpen);
  }

  @Watch('disabled')
  disabledChanged() {
    if (this.disabled) {
      this.toggle(false);
    }
  }
}
