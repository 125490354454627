import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import { MultiPolygon } from '@turf/turf';
import { User, SurveySite, FocalLandscape } from '@/api';
import dayjs from 'dayjs';
import PropertyUser from './PropertyUser';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class SurveyProperty extends ApplicationRecord {
  static jsonapiType = 'survey-properties';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Owner',
        value: 'owner.email',
      },
      {
        text: 'Sites',
        value: 'siteCount',
      },
    ];
  }

  static scopeFactory() {
    return SurveyProperty.includes(['owner']).select([
      'id',
      'name',
      'code',
      'owner',
      'focal_landscape',
      'survey_sites',
    ]);
  }

  get siteCount() {
    return this.surveySites.length;
  }

  get established() {
    return dayjs(this.createdAt).format('MMMM D, YYYY');
  }

  @Attr() name: string;

  @Attr() description: string;

  @Attr() geometry: MultiPolygon;

  @Attr() createdAt: string;

  @Attr() code: string;

  // aggregate counts for survey status
  @Attr() readonly surveyDraft: number;

  @Attr() readonly surveyPublished: number;

  @Attr() readonly surveyInProgress: number;

  @Attr() readonly surveyAssessed: number;

  // aggregate counts for detection bbox tag status
  @Attr() readonly tagStatusNone: number;

  @Attr() readonly tagStatusConflict: number;

  @Attr() readonly tagStatusAgreed: number;

  @Attr() readonly tagStatusResolved: number;

  // aggregate counts for detection bbox tag status
  @Attr() readonly snsStatusNone: number;

  @Attr() readonly snsStatusPending: number;

  @Attr() readonly snsStatusUploaded: number;

  @Attr() readonly snsStatusDetected: number;

  @Attr() readonly snsStatusDetectFailed: number;

  @Attr() readonly snsStatusPredicted: number;

  @Attr() readonly snsStatusPredictFailed: number;

  @Attr() readonly blankMediaCount: number;

  // has no animal bboxes or bboxes is blank true
  // not implemented
  // @Attr() readonly blankMediaCount: number;

  @BelongsTo() owner: User;

  @BelongsTo() focalLandscape: FocalLandscape;

  @HasMany() surveySites: SurveySite[]; // has many

  @HasMany() propertyUsers: PropertyUser[]; // has many

  get surveyCount() {
    return (
      this.surveyDraft +
      this.surveyPublished +
      this.surveyInProgress +
      this.surveyAssessed
    );
  }

  // TODO: what to include here?
  // not pending
  get faunaMediaCount() {
    return (
      this.snsStatusNone +
      // this.snsStatusPending +
      this.snsStatusUploaded +
      this.snsStatusDetected +
      this.snsStatusDetectFailed +
      this.snsStatusPredicted +
      this.snsStatusPredictFailed
    );
  }

  get totalBboxCount() {
    return (
      this.tagStatusNone +
      this.tagStatusConflict +
      this.tagStatusAgreed +
      this.tagStatusResolved
    );
  }

  get totalConflictCount() {
    return this.tagStatusConflict + this.tagStatusResolved;
  }
}
