var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","right":"","dense":"","min-width":"180","max-width":"300","max-height":"300","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-button",class:{
            'filter-button--dark': _vm.dark,
            'filter-button--has-filter': true,
          },attrs:{"ripple":false,"rounded":"","color":_vm.buttonColor}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.asc ? 'mdi-sort-ascending' : 'mdi-sort-descending')+" ")]),_vm._v(" "+_vm._s(_vm.label)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[(_vm.tooltip)?_c('span',[_vm._v(_vm._s(_vm.tooltip))]):_vm._e()])]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('div',{staticClass:"context-menu"},[_c('div',{staticClass:"context-menu-title"},[_vm._v(" Sort by ")]),_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"context-menu-item",class:{ 'context-menu-item--active': item.value === _vm.value },attrs:{"role":"button"},on:{"click":function($event){return _vm.setValue(item.value)}}},[_c('div',{staticClass:"context-menu-item__title"},[_vm._v(" "+_vm._s(item.label)+" ")]),(item.value === _vm.value)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.asc ? 'mdi-sort-ascending' : 'mdi-sort-descending')+" ")]):_vm._e()],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }