









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class StatBtn extends Vue {
  @Prop({ default: '' }) readonly icon: string;
}
