






































































































import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { FaunaMedia, FaunaSurvey } from '@/api';

import { debounce } from 'lodash';

import snackModule from '@/store/Snack';
import confirmDialog from '@/confirm-dialog';

import FaunaMediaGalleryItem from '@/components/upload/FaunaMediaGalleryItem.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';
import CompactPagination from '@/components/upload/CompactPagination.vue';

@Component({
  components: {
    FaunaMediaGalleryItem,
    AbstractTable,
    CompactPagination,
  },
})
export default class FaunaMediaProblemTable extends Vue {
  @Prop({ required: true }) readonly survey: FaunaSurvey;

  faunaMedia: FaunaMedia[] = [];

  loading = false;

  deleteDialog = false;

  deleteProgress = 100; // 0 - 100

  selected: string[] = []; // fm ids

  total = 0;

  page = 1;

  statusIn = ['none', 'uploaded', 'detected', 'predicted'];

  get snsStatusItems() {
    return [
      { label: 'None', value: 'none' },
      { label: 'Pending', value: 'pending' },
      { label: 'Uploaded', value: 'uploaded' },
      { label: 'Detected', value: 'detected' },
      { label: 'Predicted', value: 'predicted' },
      { label: 'Detect Failed', value: 'detect_failed' },
      { label: 'Predict Failed', value: 'predict_failed' },
    ];
  }

  get scopeFactory() {
    return () =>
      FaunaMedia.where({
        faunaSurvey: this.survey.id,
        sns_status__in: this.statusIn,
      }).order({ timestamp: 'asc' });
  }

  get itemsPerPage() {
    return 30;
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  async doUpdate() {
    try {
      this.loading = true;
      this.selected = [];

      const result = await this.scopeFactory()
        .per(this.itemsPerPage)
        .page(this.page)
        .all();
      this.faunaMedia = result.data;
      this.total = result.meta.pagination.count;
      this.$emit('update');
    } catch (e) {
      this.faunaMedia = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  toggleStatusItem(status: string) {
    const i = this.statusIn.indexOf(status);
    if (i === -1) {
      this.statusIn.push(status);
    } else {
      this.statusIn.splice(i, 1);
    }
  }

  selectAll() {
    this.selected = this.faunaMedia.map(item => item.id as string);
  }

  deselectAll() {
    this.selected = [];
  }

  /**
   * deletes the selected items
   * (maaaany requests not very efficient)
   */
  async deleteSelected() {
    const confirm = await confirmDialog();
    if (confirm !== 'confirm') {
      return;
    }

    const max = this.selected.length;
    let count = 0;

    const promises = this.selected.map(async itemId => {
      try {
        const item = new FaunaMedia({ id: itemId });
        item.isPersisted = true;

        await item.destroy();
        return Promise.resolve();
      } catch (e) {
        snackModule.setError({
          text: 'Error deleting file',
          errors: (e as ErrorResponse).response.errors,
        });
        return Promise.resolve();
      } finally {
        count += 1;
        this.deleteProgress = (100 * count) / max;
      }
    });

    await Promise.all(promises);
    this.update();
  }

  mounted() {
    this.update();
  }

  @Watch('page')
  @Watch('statusIn')
  pageChanged() {
    this.update();
  }

  @Watch('deleteProgress')
  deleteProgressChanged() {
    if (!this.deleteDialog) {
      this.deleteDialog = true;
    }
  }
}
