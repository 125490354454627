import { Model, Attr, BelongsTo, HasOne } from 'spraypaint';
import { Point } from '@turf/turf';
import {
  SurveyProperty,
  HabitatType,
  VegetationStructureType,
  RecruitmentType,
  ZoneType,
} from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class SurveySite extends ApplicationRecord {
  static jsonapiType = 'survey-sites';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Property',
        value: 'surveyProperty.name',
      },
      {
        text: 'Vegetation Structure Type',
        value: 'vegetationStructureType.name',
      },
      {
        text: 'Recruitment Type',
        value: 'recruitmentType.name',
      },
      {
        text: 'Habitat Type',
        value: 'habitatType.name',
      },
      {
        text: 'Zone Type',
        value: 'zoneType.name',
      },
    ];
  }

  static scopeFactory() {
    return SurveySite.includes([
      'surveyProperty',
      'vegetationStructureType',
      'recruitmentType',
      'habitatType',
      'zoneType',
    ]).select([
      'id',
      'name',
      'code',
      'surveyProperty',
      'vegetationStructureType',
      'recruitmentType',
      'habitatType',
      'zoneType',
    ]);
  }

  @Attr() name: string;

  @Attr() description: string;

  @Attr() location: Point;

  @Attr() createdAt: string;

  @Attr() faunaSurveyCount: number;

  @Attr() code: string;

  // aggregate counts for survey status
  @Attr() readonly surveyDraft: number;

  @Attr() readonly surveyPublished: number;

  @Attr() readonly surveyInProgress: number;

  @Attr() readonly surveyAssessed: number;

  // aggregate counts for detection bbox tag status
  @Attr() readonly tagStatusNone: number;

  @Attr() readonly tagStatusConflict: number;

  @Attr() readonly tagStatusAgreed: number;

  @Attr() readonly tagStatusResolved: number;

  // aggregate counts for detection bbox tag status
  @Attr() readonly snsStatusNone: number;

  @Attr() readonly snsStatusPending: number;

  @Attr() readonly snsStatusUploaded: number;

  @Attr() readonly snsStatusDetected: number;

  @Attr() readonly snsStatusDetectFailed: number;

  @Attr() readonly snsStatusPredicted: number;

  @Attr() readonly snsStatusPredictFailed: number;

  // has no animal bboxes or bboxes is blank true
  // not implemented
  // @Attr() readonly blankMediaCount: number;

  @BelongsTo() surveyProperty: SurveyProperty;

  @HasOne() vegetationStructureType: VegetationStructureType;

  @HasOne() recruitmentType: RecruitmentType;

  @HasOne() habitatType: HabitatType;

  @HasOne() zoneType: ZoneType | null;

  get surveyCount() {
    return (
      this.surveyDraft +
      this.surveyPublished +
      this.surveyInProgress +
      this.surveyAssessed
    );
  }

  // TODO: what to include here?
  get faunaMediaCount() {
    return (
      this.snsStatusNone +
      // this.snsStatusPending +
      this.snsStatusUploaded +
      this.snsStatusDetected +
      this.snsStatusDetectFailed +
      this.snsStatusPredicted +
      this.snsStatusPredictFailed
    );
  }

  get totalBboxCount() {
    return (
      this.tagStatusNone +
      this.tagStatusConflict +
      this.tagStatusAgreed +
      this.tagStatusResolved
    );
  }

  get totalConflictCount() {
    return this.tagStatusConflict + this.tagStatusResolved;
  }
}
