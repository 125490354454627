







import { Component, Vue, Prop } from 'vue-property-decorator';

import { DetectionBbox } from '@/api';

@Component({
  components: {},
})
export default class BboxPreview extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;

  @Prop({ required: true }) readonly thumbnail: string;

  natW = 0;

  natH = 0;

  imgW = 0;

  imgH = 0;

  get imgRef() {
    return this.$refs.img as HTMLImageElement;
  }

  get scale() {
    return this.imgW / this.natW;
  }

  /**
   * handler for image onLoad
   * get the natural image size and set vars for positioning
   */
  onLoad() {
    if (!this.$refs.img) {
      console.warn('no image ref, bailing early');
      return;
    }

    // natural image size
    this.natW = this.imgRef.naturalWidth;
    this.natH = this.imgRef.naturalHeight;

    this.imgW = this.imgRef.offsetWidth;
    this.imgH = this.imgRef.offsetHeight;
  }

  /**
   * This returns an array of bboxes and their styles
   * used to render the bboxes on the image
   */
  get bboxStyle() {
    console.log(this.scale, this.imgW, this.natW);
    if (!this.bbox) {
      return '';
    }

    // for the actual bounding box
    const left = this.bbox.bbox.x * this.scale;
    const top = this.bbox.bbox.y * this.scale;
    const width = this.bbox.bbox.w * this.scale;
    const height = this.bbox.bbox.h * this.scale;

    const outlineStyle = [
      `left: ${left}px`,
      `top: ${top}px`,
      `width: ${width}px`,
      `height: ${height}px`,
    ].join('; ');

    return outlineStyle;
  }
}
