
















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AdminNav extends Vue {
  menu = [
    {
      title: 'Admin Home',
      exact: true,
      to: {
        name: 'admin-home',
      },
      show: () => true,
    },
    {
      title: 'Users',
      to: {
        name: 'admin-users',
      },
      show: () => true,
    },
    {
      title: 'Focal Landscapes',
      to: {
        name: 'admin-focal-landscapes',
      },
      show: () => true,
    },
    {
      title: 'Properties',
      to: {
        name: 'admin-properties',
      },
      show: () => true,
    },
    {
      title: 'Sites',
      to: {
        name: 'admin-sites',
      },
      show: () => true,
    },
    {
      title: 'Surveys',
      to: {
        name: 'admin-surveys',
      },
      show: () => true,
    },
    {
      title: 'Fauna Tag Groups',
      to: {
        name: 'admin-fauna-tag-groups',
      },
      show: () => true,
    },
    {
      title: 'Fauna Tags',
      to: {
        name: 'admin-fauna-tags',
      },
      show: () => true,
    },
    {
      title: 'Programs',
      to: {
        name: 'admin-programs',
      },
      show: () => true,
    },
  ];

  get filteredMenu() {
    return this.menu.filter(item => item.show());
  }
}
