import { render, staticRenderFns } from "./ClassifierImageInfo.vue?vue&type=template&id=2994fb1f&"
import script from "./ClassifierImageInfo.vue?vue&type=script&lang=ts&"
export * from "./ClassifierImageInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ClassifierImageInfo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VMenu,VSpacer,VTooltip})
