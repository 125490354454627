




































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class FaunaMediaUploader extends Vue {
  inDrag = false;

  /**
   * trigger the hidden file input field to show system dialog
   */
  async initFileUpload() {
    if (this.$refs.fileInput) {
      (this.$refs.fileInput as HTMLInputElement).click();
    }
  }

  /**
   * The event that gets triggered when files are selected using system dialog
   */
  onFileChange(e: Event) {
    const { files } = e.target as HTMLInputElement;
    if (!files) {
      console.warn('no files');
      return;
    }
    this.$emit('upload', Array.from(files));
  }

  /**
   * Handle the drop event
   * gets the presigned urls for the files and starts the upload
   */
  async onDrop(e: DragEvent) {
    e.preventDefault();
    this.inDrag = false;

    if (!e.dataTransfer) {
      console.warn('no data transfer - no files??');
      return;
    }
    this.$emit('upload', Array.from(e.dataTransfer.files));
  }

  /**
   * Sets the UI for dropping files
   */
  onDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.inDrag = true;
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'copy';
    }
  }

  /**
   * Resets the UI for dropping files
   */
  onDragLeave(e: DragEvent) {
    e.preventDefault();
    this.inDrag = false;
  }
}
