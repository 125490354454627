





















































import { Component, Vue, Prop } from 'vue-property-decorator';

import { DetectionBbox } from '@/api';

import UserAvatar from '@/components/common/UserAvatar.vue';

import authModule from '@/store/Auth';
import cacheModule from '@/store/Cache';

@Component({
  components: {
    UserAvatar,
  },
})
export default class BboxItem extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;

  // whether the bbox is active or not
  @Prop({ required: true }) readonly active: boolean;

  // coords of the image
  // used to position the bbox and mask on the image
  @Prop({ required: true }) readonly x: number;

  @Prop({ required: true }) readonly y: number;

  @Prop({ required: true }) readonly w: number;

  @Prop({ required: true }) readonly h: number;

  // the ratio between actual size and natural image size
  // used to scale the bbox to fit the image
  @Prop({ required: true }) readonly scale: number;

  get me() {
    return authModule.user;
  }

  get aiTag() {
    const fmTag = this.bbox.aiTag;
    return fmTag
      ? {
          faunaMediaTag: fmTag,
          faunaTag: cacheModule.faunaTagsById(fmTag.faunaTag.id as string),
        }
      : null;
  }

  get expertTag() {
    const fmTag = this.bbox.expertTag;
    return fmTag
      ? {
          faunaMediaTag: fmTag,
          faunaTag: cacheModule.faunaTagsById(fmTag.faunaTag.id as string),
        }
      : null;
  }

  get userTag() {
    const fmTag = this.bbox.userTag;
    return fmTag
      ? {
          faunaMediaTag: fmTag,
          faunaTag: cacheModule.faunaTagsById(fmTag.faunaTag.id as string),
        }
      : null;
  }

  get resolvedTag() {
    const fmTag = this.bbox.resolvedTag;
    return fmTag
      ? {
          faunaMediaTag: fmTag,
          faunaTag: cacheModule.faunaTagsById(fmTag.faunaTag.id as string),
        }
      : null;
  }

  get bboxStatus() {
    return `bbox--${this.bbox.tagStatus}`;
  }

  /**
   * This returns an array of bboxes and their styles
   * used to render the bboxes on the image
   */
  get styles() {
    // outer box style
    const outerStyle = [
      `left: ${this.x}px`,
      `top: ${this.y}px`,
      `width: ${this.w}px`,
      `height: ${this.h}px`,
    ].join('; ');

    // for the actual bounding box
    const left = this.bbox.bbox.x * this.scale;
    const top = this.bbox.bbox.y * this.scale;
    const width = this.bbox.bbox.w * this.scale;
    const height = this.bbox.bbox.h * this.scale;

    const outlineStyle = [
      `left: ${left}px`,
      `top: ${top}px`,
      `width: ${width}px`,
      `height: ${height}px`,
    ].join('; ');

    // for the info box
    const infoStyle = [];
    const topDistance = top;
    const btmDistance = this.h - (top + height);

    infoStyle.push(left > this.w / 2 ? `right: 0` : `left: 0`);
    infoStyle.push(
      topDistance < btmDistance
        ? `top: calc(100% + 0.25rem)`
        : `bottom: calc(100% + 0.25rem)`,
    );

    // for creating a transluscent clip-path overlay
    const x1 = (100 * this.bbox.bbox.x * this.scale) / this.w;
    const y1 = (100 * this.bbox.bbox.y * this.scale) / this.h;
    const x2 = x1 + (100 * (this.bbox.bbox.w * this.scale)) / this.w;
    const y2 = y1 + (100 * (this.bbox.bbox.h * this.scale)) / this.h;

    const clipPathPoly = [
      `0% 0%`,
      `0% 100%`,
      `${x1}% 100%`,
      `${x1}% ${y1}%`,
      `${x2}% ${y1}%`,
      `${x2}% ${y2}%`,
      `${x1}% ${y2}%`,
      `${x1}% 100%`,
      `100% 100%`,
      `100% 0%`,
    ];

    return {
      outerStyle,
      maskStyle: `clip-path: polygon(${clipPathPoly.join(', ')})`,
      outlineStyle,
      infoStyle: infoStyle.join('; '),
    };
  }

  onClick(event: MouseEvent) {
    this.$emit('click', this.bbox, event);
  }
}
