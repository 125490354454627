import { Model, Attr, HasOne, HasMany } from 'spraypaint';
import { FaunaMedia, FaunaMediaTag, TaggerType, TagStatusType } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class DetectionBbox extends ApplicationRecord {
  static jsonapiType = 'detection-bboxes';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Timestamp',
        value: 'timestamp',
      },
    ];
  }

  static scopeFactory() {
    return DetectionBbox.includes([]);
  }

  @Attr() createdAt: string;

  // person / vehicle auto filtered out in survey counts
  @Attr() category: 'animal' | 'person' | 'vehicle';

  // set this for false positives
  // will be auto filtered out in survey counts
  @Attr() isBlank: boolean;

  // bbox coordinates
  // TODO: would be nice if this was normalized from 0 - 1
  @Attr() bbox: { x: number; y: number; w: number; h: number };

  // prediction data of the bbox
  @Attr() prediction: Array<{ conf: number; label: string }>;

  // ai confidence level of the detection bbox
  @Attr() confidence: number;

  // bbox index from ai
  @Attr() index: number;

  // bbox tags are either none, conflict, agreed or resolved
  @Attr() tagStatus: TagStatusType;

  // the type of tagger that has been chosen as the final tag
  @Attr() finalTaggerType: TaggerType | null;

  @HasOne() faunaMedia: FaunaMedia;

  @HasMany() faunaMediaTags: FaunaMediaTag[];

  get aiTag() {
    return this.faunaMediaTags.find(ft => ft.taggerType === TaggerType.ai);
  }

  get expertTag() {
    return this.faunaMediaTags.find(ft => ft.taggerType === TaggerType.expert);
  }

  get userTag() {
    return this.faunaMediaTags.find(ft => ft.taggerType === TaggerType.user);
  }

  get resolvedTag() {
    return this.finalTaggerType
      ? this.faunaMediaTags.find(ft => ft.taggerType === this.finalTaggerType)
      : null;
  }

  // multiply the detection confidence by the confidence of the first prediction
  get aiConfidence() {
    return this.confidence * (this.prediction[0] ? this.prediction[0].conf : 0);
  }
}
