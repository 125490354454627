

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';
import LineIndicator from '@/components/common/LineIndicator.vue';

import { numberFormat } from '@/util';

@Component({
  components: {
    LineIndicator,
  },
})
export default class StatTile extends Vue {
  @Prop({ required: false, default: false }) readonly to: Location | false;

  @Prop({ required: true }) readonly title: string;

  @Prop({ required: true }) readonly subtitle: string;

  @Prop({ required: true }) readonly value: number;

  @Prop({ required: true }) readonly max: number;

  get numberFormat() {
    return numberFormat;
  }

  get percent() {
    return `${Math.floor((this.value / this.max) * 100)}%`;
  }
}
